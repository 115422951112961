export const CardGrid = el => {
  const ui = {
    el,
    parentDivs: document.querySelectorAll('.card-grid__card')
  };
  document.addEventListener('DOMContentLoaded', function () {
    ui.el.addEventListener('focusin', function (event) {
      if (event.target.matches('a.card-is-link')) {
        const parentDiv = event.target.closest('.card-grid__card');
        if (parentDiv) {
          parentDiv.style.outline = '2px solid #219EBC';
        }
      }
    });
    ui.el.addEventListener('focusout', function (event) {
      if (event.target.matches('a.card-is-link')) {
        const parentDiv = event.target.closest('.card-grid__card');
        if (parentDiv) {
          parentDiv.style.outline = 'none';
        }
      }
    });
    ui.el.addEventListener('mouseover', function (event) {
      if (event.target.matches('a.card-is-link')) {
        const parentDiv = event.target.closest('.card-grid__card');
        if (parentDiv) {
          parentDiv.style.outline = '2px solid #9E1B32';
        }
      }
    });
    ui.el.addEventListener('mouseout', function (event) {
      if (event.target.matches('a.card-is-link')) {
        const parentDiv = event.target.closest('.card-grid__card');
        if (parentDiv) {
          parentDiv.style.outline = 'none';
        }
      }
    });
  });
};
export default CardGrid;