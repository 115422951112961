import { Swiper } from 'swiper';
import { Navigation } from 'swiper/modules';
export const QuoteSlider = el => {
  const ui = {
    el,
    swiperEl: el.querySelector('[data-js-quote-swiper]'),
    nextBtn: el.querySelector('[data-js-quote-next]'),
    prevBtn: el.querySelector('[data-js-quote-prev]')
  };
  const quoteSwiper = new Swiper(ui.swiperEl, {
    init: true,
    spaceBetween: 40,
    allowTouchMove: true,
    slidesPerView: 1,
    effect: 'slide',
    speed: 500,
    initialSlide: 0,
    loop: true,
    // Enable loop
    loopAdditionalSlides: 1,
    // Number of additional slides to append/prepend to the beginning/end
    lazyLoadingInPrevNext: true,
    navigation: {
      nextEl: ui.nextBtn,
      prevEl: ui.prevBtn
    },
    modules: [Navigation]
  });
  function checkButtonVisibility() {
    if (quoteSwiper.slides.length === 1) {
      const buttonCont = ui.el.querySelector('[data-js-button-container]');
      buttonCont.classList.add('hidden');
      buttonCont.setAttribute('aria-hidden', 'true');
    }
  }
  function setAriaHiddenAttributes() {
    quoteSwiper.slides.forEach((slide, index) => {
      const isCurrentSlide = index === quoteSwiper.activeIndex;
      slide.setAttribute('aria-hidden', !isCurrentSlide);
    });
  }
  function buttonSlide() {
    const activeSlide = quoteSwiper.slides[quoteSwiper.activeIndex];
    const container = activeSlide.querySelector('[data-js-quote-container]');
    const containerHeight = container.offsetHeight;
    const buttonContainer = ui.el.querySelector('[data-js-button-container]');
    buttonContainer.style.position = 'absolute';
    buttonContainer.style.top = containerHeight + 10 + 'px';
  }
  function updateElHeight() {
    const activeSlide = quoteSwiper.slides[quoteSwiper.activeIndex];
    const activeContainer = activeSlide.querySelector('[data-js-quote-container]');
    const activeContainerHeight = activeContainer.offsetHeight;
    const elHeight = activeContainerHeight + 40;
    el.style.height = elHeight + 'px';
  }
  function updateSliderLayout() {
    quoteSwiper.update();
    buttonSlide();
    updateElHeight();
  }
  window.addEventListener('resize', updateSliderLayout);
  updateSliderLayout();
  buttonSlide();
  updateElHeight();
  setAriaHiddenAttributes();
  checkButtonVisibility();
  quoteSwiper.on('slideChange', () => {
    updateElHeight();
    buttonSlide();
    setAriaHiddenAttributes();
  });
};
export default QuoteSlider;