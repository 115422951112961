export const VideoHero = el => {
  const ui = {
    el,
    embeddedVideo: el.querySelector('#hero-iframe'),
    imageOverlay: el.querySelector('#video-overlay')
  };
  ui.embeddedVideo.onload = () => {
    ui.imageOverlay.style.display = 'none';
  };
};
export default VideoHero;