import MicroModal from 'micromodal';
import populateBioModal from '@k-bits/bios/bio-modals';
export const BioCards = el => {
  const ui = {
    el,
    modalBtns: el.querySelectorAll('[data-js-bio-modal]')
  };

  /**
   * For each Bio Card button, initiate MicroModal (required MicroModal and populateBioModal )
   */
  for (var i = ui.modalBtns.length - 1; i >= 0; i--) {
    ui.modalBtns[i].addEventListener('click', e => {
      const modalData = e.currentTarget.getAttribute('data-all-bio-fields');
      initModal(modalData);
    });
    ui.modalBtns[i].addEventListener('keydown', e => {
      if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
        const modalData = e.currentTarget.getAttribute('data-all-bio-fields');
        initModal(modalData);
      }
    });
  }
  function initModal(modalData) {
    // Micromodal for each Bio;
    MicroModal.show('bio-cards-modal', {
      onShow: modal => populateBioModal(modalData, modal),
      openTrigger: 'data-bio-cards-modal-trigger',
      closeTrigger: 'data-bio-cards-modal-close',
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true
    });
  }
};
export default BioCards;